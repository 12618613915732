<template>
  <div>
    <section v-if="!customizationVisible" class="mt-1">
      <el-button
        style="float: right"
        type="primary"
        @click="addNewCustomization"
        icon="el-icon-circle-plus-outline"
        class="mb-1"
        >Create New</el-button
      >
      <el-table :data="customizationData" v-loading="loading">
        <el-table-column label="Label" prop="label" width="300">
        </el-table-column>
        <el-table-column label="Status">
          <div class="d-flex">
            <div class="active circle"></div>
            <p>Active</p>
          </div>
        </el-table-column>
        <el-table-column label="Main Customization">
          <template slot-scope="scope">
            <el-switch
              active-text="Yes"
              inactive-text="No"
              v-model="scope.row.isActive"
              :disabled="disableCustomization(scope.row.isActive, scope.$index)"
              @change="updateCustomization(scope.$index)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="Actions">
          <template slot-scope="scope">
            <el-button
              size="mini"
              circle
              plain
              type="warning"
              @click="editCustomization(scope.$index)"
              ><i class="el-icon-edit"
            /></el-button>
            <el-button
              size="mini"
              circle
              plain
              type="danger"
              @click="deleteCustomization(scope.$index)"
              :disabled="scope.row.isActive && customizationData.length > 1"
              ><i class="el-icon-delete"
            /></el-button>
          </template>
        </el-table-column>
        <el-table-column label="Last modified" prop="updated_at">
          <template slot-scope="scope">
            {{ scope.row.updated_at | globalDateTimeFormat }}
          </template>
        </el-table-column>
      </el-table>
    </section>
    <EntityViewConfiguration
      v-if="customizationVisible"
      :currentEntity="currentEntity"
      :customization="customization"
      :relationships="relationshipsData"
      :nestedRelationships="nestedRelationshipsData"
      :entities="entities"
      :formBuilders="formBuilders"
      :entityViews="entityViews"
      :docTemplates="docTemplates"
      @goBack="goBack"
    >
    </EntityViewConfiguration>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { makeSlug } from "../../helpers/appHelper";
// import * as _ from "lodash";
import { uniqBy } from "../../helpers/lodashHelper";
import EntityViewConfiguration from "./EntityViewConfiguration.vue";
import {
  fetchAllEntityCustomizationsByEntity,
  deleteEntityCustomization,
  updateCustomizationStatus,
} from "@/repo/entityCustomizationRepo";
import { postAPICall } from "../../helpers/httpHelper";
import templateBuilderHelper from "../../mixins/templateBuilderHelper";
export default {
  name: "EntityCustomization",
  components: {
    EntityViewConfiguration,
  },
  mixins: [templateBuilderHelper],
  props: ["currentEntity"],
  computed: {
    ...mapGetters("entityCustomization", [
      // "getAllCustomizations",
      "getCustomizationDeleteStatus",
    ]),
    ...mapGetters("entityRelationships", [
      "getEntityRelationships",
      "getEntityNestedRelationships",
    ]),
  },
  data() {
    return {
      customizationData: [],
      customization: null,
      customizationVisible: false,
      relationshipsData: [],
      nestedRelationshipsData: [],
      loading: false,
      formBuilders: [],
      entities: [],
      docTemplates: [],
      entityViews: [],
    };
  },
  async mounted() {
    let formBuilders = [],
      docTemplates = [];
    [
      this.entities,
      this.entityViews,
      formBuilders,
      docTemplates,
    ] = await Promise.all([
      this.fetchAllEntities(),
      this.fetchAllEntityViews(),
      postAPICall("GET", "/formBuilders", { get_all: true }),
      postAPICall("GET", "/document-templates", { get_all: true }),
      this.fetchAllCustomizations(),
      this.fetchRelationshipsAndNestedRelationships(),
    ]);
    if (docTemplates?.data) {
      this.docTemplates = (docTemplates.data || []).map((e) => {
        return {
          value: e._id + "#" + e.configurable_document_id,
          title: e.title,
        };
      });
    }
    if (formBuilders?.data) {
      this.formbuilderList = formBuilders.data;
    }
  },
  methods: {
    async updateCustomization(index) {
      this.loading = true;
      let updatedCustomization = this.customizationData[index];
      let oldCustomization = this.customizationData.find(
        (data, i) => data.isActive && index != i
      );
      oldCustomization = { ...oldCustomization, ...{ isActive: false } };
      await Promise.all([
        this.$store.dispatch(
          "entityCustomization/updateCustomization",
          updatedCustomization
        ),
        this.$store.dispatch(
          "entityCustomization/updateCustomization",
          oldCustomization
        ),
        updateCustomizationStatus(updatedCustomization._id, true),
        updateCustomizationStatus(oldCustomization._id, false),
      ]);
      this.fetchAllCustomizations();
    },
    disableCustomization(value, index) {
      if (!value) {
        return false;
      } else {
        let otherCustomizationsVisble = this.customizationData.filter(
          (db, i) => db.isActive && i !== index
        );
        if (otherCustomizationsVisble.length) {
          return false;
        }
        return true;
      }
    },
    async fetchAllCustomizations() {
      this.loading = true;
      // await this.$store.dispatch(
      //   "entityCustomization/fetchAllEntityCustomizations",
      //   this.currentEntity._id
      // );
      const getAllCustomizations = await fetchAllEntityCustomizationsByEntity(
        this.currentEntity._id
      );
      if (getAllCustomizations?.length) {
        this.customizationData = [...getAllCustomizations];
      }
      this.loading = false;
    },
    async addNewCustomization() {
      this.customization = {
        label: this.currentEntity.name,
        buttons: [
          {
            buttonName: "Save",
            value: "Save",
            visibility: true,
          },
          {
            buttonName: "Save & Next",
            value: "Save & Next",
            visibility: true,
          },
          {
            buttonName: "Save & New",
            value: "Save & New",
            visibility: true,
          },
          {
            buttonName: "Edit",
            value: "Edit",
            visibility: true,
          },
          {
            buttonName: "Update & Next",
            value: "Update & Next",
            visibility: true,
          },
          {
            buttonName: "Duplicate",
            value: "Duplicate",
            visibility: false,
          },
          {
            buttonName: "Send Notification",
            value: "Send Notification",
            visibility: true,
          },
          {
            buttonName: "View",
            value: "View",
            visibility: true,
          },
          {
            buttonName: "Delete",
            value: "Delete",
            visibility: true,
          },
          {
            buttonName: "Attachments",
            value: "Attachments",
            visibility: true,
          },
        ],
        tab_groups: [
          {
            label: "Details",
            tabs: this.currentEntity.templates.map((temp) => {
              return {
                label: temp.templateInfo.name,
                feature_id: temp.template_id,
                feature_type: "TEMPLATE",
              };
            }),
          },
          ...this.relationshipsData.map((rel) => {
            return {
              label: rel.relationship_title,
              tabs: [
                {
                  label: rel.relationship_title,
                  feature_type: "RELATIONSHIP",
                  feature_id: rel._id,
                  permissions: {
                    add: true,
                    assign: true,
                    total: false,
                    view: false,
                    redirect: true,
                    assign_type: "default_view",
                    bulkUpdate: false,
                  },
                },
              ],
            };
          }),
          {
            label: "Files",
            tabs: [
              {
                label: "Files",
                feature_type: "OTHERS",
                feature_id: "Files",
              },
            ],
          },
          {
            label: "eSignatures",
            tabs: [
              {
                label: "eSignatures",
                feature_type: "OTHERS",
                feature_id: "eSignatures",
              },
            ],
          },
          {
            label: "Notifications",
            tabs: [
              {
                label: "Notifications",
                feature_type: "OTHERS",
                feature_id: "Notifications",
              },
            ],
          },
        ],
        actions: [],
        active_layout:
          this.currentEntity.entity_type == "INDIVIDUAL"
            ? "PROFILE"
            : this.currentEntity.entity_type == "BUSINESS"
            ? "COVER"
            : "STANDARD",
        isActive: this.customizationData.length ? false : true,
      };
      this.customizationVisible = true;
    },
    async fetchRelationshipsAndNestedRelationships() {
      await this.$store.dispatch(
        "entityRelationships/fetchEntityRelationships",
        { entity_id: this.currentEntity._id }
      );
      this.relationshipsData = [...(this.getEntityRelationships || [])];
      await this.$store.dispatch(
        "entityRelationships/fetchEntityNestedRelationships",
        {
          entity_id: this.currentEntity._id,
          fetchAll: true,
        }
      );
      this.nestedRelationshipsData = [
        ...(this.getEntityNestedRelationships || []),
      ];
      this.relationshipsData = this.relationshipsData.map((e) => {
        if (e.parent_entity_id == this.$route.params.entity_id) {
          e.owner_type = "PARENT";
          e.relationship_title = e.child_relation_title;
          e.relationship_slug = makeSlug(e.relationship_title);
          e.relationship_id = e.child_entity_id;
        } else {
          e.owner_type = "CHILD";
          e.relationship_title = e.parent_relation_title;
          e.relationship_slug = makeSlug(e.relationship_title);
          e.relationship_id = e.parent_entity_id;
        }
        return e;
      });
      this.relationshipsData = this.relationshipsData.filter((e) => {
        if (e.owner_type == "CHILD" && !e.is_bidirectional) {
          return false;
        } else {
          return true;
        }
      });
      this.relationshipsData = this.relationshipsData.filter(
        (e) => e.owner_type == "CHILD" || e.representation == "TAB"
      );
      this.nestedRelationshipsData = this.nestedRelationshipsData.map((e) => {
        e.relationship_title =
          e.parent_relationship_details.relationship_title +
          "/" +
          e.relationship_title;
        return e;
      });
      this.nestedRelationshipsData = uniqBy(
        this.nestedRelationshipsData,
        (rel) => {
          return rel._id;
        }
      );
    },
    goBack(fetch = false) {
      this.customizationVisible = false;
      if (fetch) {
        this.fetchAllCustomizations();
      }
    },
    editCustomization(index) {
      this.customization = { ...this.customizationData[index] };
      this.customizationVisible = true;
    },
    deleteCustomization(index) {
      this.$confirm(
        "Do you want to delete this customization ?",
        "Are you sure ?",
        {
          confirmButtonText: "Ok",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(async () => {
        let id = this.customizationData[index]._id;
        await this.$store.dispatch(
          "entityCustomization/deleteCustomization",
          id
        );
        if (this.getCustomizationDeleteStatus.success) {
          await deleteEntityCustomization(id);
          this.$notify({
            title: "Sucess",
            message: "Customization deleted successfully",
            type: "success",
          });
          this.fetchAllCustomizations();
        }
      });
    },
  },
};
</script>
<style scoped>
.circle {
  position: relative;
  padding-left: 20px;
  text-transform: capitalize;
  font-size: 14px;
  &::after {
    background: #635e5e;
    content: "";
    position: absolute;
    top: 4px;
    left: 0;
    height: 10px;
    width: 10px;
    display: inline-block;
    border-radius: 50%;
  }
  &.active::after {
    background: #08ad36;
  }
  &.hold::after {
    background: #f19203;
  }
  &.onreview::after {
    background: #2f80ed;
  }
  &.inactive::after {
    background: #756a6a;
  }
  &.declined::after {
    background: #f03232;
  }
  &.ACTIVE::after {
    background: #08ad36;
  }
  &.HOLD::after {
    background: #f19203;
  }
  &.ONREVIEW::after {
    background: #2f80ed;
  }
  &.INACTIVE::after {
    background: #756a6a;
  }
  &.DECLINED::after {
    background: #f03232;
  }
}
</style>
